import React from 'react';
import Layout from '../components/Layout';
import SubPageWrapper from '../components/SubPageWrapper';
import Inner from '../components/Inner';
import Box from '../components/Box';
import { Link } from 'gatsby';
import Seo from '../components/seo';

export default function NotFound() {
  return (
    <Layout>
      <Seo
        url="/404"
        title="ページが見つかりません"
        meta={[
          {
            name: 'robots',
            content: 'noindex, nofollow',
          },
          {
            name: 'googlebot',
            content: 'noindex, nofollow',
          },
        ]}
      />
      <SubPageWrapper>
        <Inner>
          <Box>
            <h1>ページが見つかりません</h1>
            <p>お探しのページは、移動または削除された可能性があります。</p>
            <Link to="/">トップページへ戻る</Link>
          </Box>
        </Inner>
      </SubPageWrapper>
    </Layout>
  );
}
